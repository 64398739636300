import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LanguageTranslateService {
  loader$: Subject<boolean> = new Subject();
  constructor(private translate: TranslateService) {}
  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
  showSpinner() {
    this.loader$.next(true);
  }
  hideSpinner() {
    this.loader$.next(false);
  }
}
