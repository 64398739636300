import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './modules/auth/components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';
import { RoleGuard } from './core/guards/auth.guard';
import { ChangePasswordComponent } from './modules/auth/components/change-password/change-password.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: 'forgot-password', component: ForgetPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [RoleGuard],
    data: { expectedRole: ['Admin'] },
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'referral',
    canActivate: [RoleGuard],
    data: { expectedRole: ['Admin'] },
    loadChildren: () =>
      import('./modules/referral/referral.module').then(
        (m) => m.ReferralModule,
      ),
  },
  {
    path: 'users',
    canActivate: [RoleGuard],
    data: { expectedRole: ['Admin'] },
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'logs',
    canActivate: [RoleGuard],
    data: { expectedRole: ['Admin'] },
    loadChildren: () =>
      import('./modules/logs/logs.module').then((m) => m.LogsModule),
  },
  {
    path: 'user-referrals',
    canActivate: [RoleGuard],
    data: { expectedRole: ['User'] },
    loadChildren: () =>
      import('./modules/user-refer/user-refer.module').then(
        (m) => m.UserReferModule,
      ),
  },
  {
    path: 'master',
    canActivate: [RoleGuard],
    data: { expectedRole: ['Admin'] },
    loadChildren: () =>
      import('./modules/master/master.module').then((m) => m.MasterModule),
  },
  { path: '**', redirectTo: '/auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
